
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiToolbar-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.MuiToolbar-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Carousel-button-22 {
  top: calc(50% - 20px) !important;
  color: black !important;
  cursor: pointer;
  margin: 0 10px;
  position: relative;
  font-size: 53px;
  transition: 200ms;
  background-color: #fdfdfd !important;
}


.MuiFormLabel-root.Mui-focused {
  color: #777 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #777 !important;
}

.iiz {
  max-width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.iiz--drag .iiz__zoom-img--visible {
  cursor: -webkit-grab;
  cursor: grab;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
}

.iiz__img--hidden {
  visibility: hidden;
  opacity: 0;
}

.iiz__img--abs {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
  -ms-touch-action: none;
  touch-action: none;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.iiz__btn:before {
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

.iiz__close::before {
  content: " ";
  width: 29px;
  height: 29px;
  background-image:
    -webkit-gradient(linear, left top, left bottom, from(#222), to(#222)),
    -webkit-gradient(linear, left top, left bottom, from(#222), to(#222));
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.MuiAppBar-positionStatic {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%, 0), 0px 4px 5px 0px rgb(0 0 0 / 14%, 0), 0px 1px 10px 0px rgb(0 0 0 / 12%, 0) !important;
}

.disable-text-selection{
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.MuiPaper-root-MuiDialog-paper {
  background-color: green !important;
}

.vertical-scroll::-webkit-scrollbar {
  display: none;
}

.vertical-scroll{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.topHeader p {
  text-align: center;
}

li {
  list-style: none;
}
form {
  padding: 0px !important;
}