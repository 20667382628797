

.header-icons {
    display: flex;
    padding: 6px;
    position: relative;
    width: 350px;
    height: 25px;
    align-items: center;
    justify-content: flex-end;
}