

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column

}
.MuiStepButton-root	 {
    span{
        background-color:'red' !important;
    }
}
.button {
    color: rgb(0, 0, 0) !important;
    border-width: 1px !important;
    width: 100% !important;
    border: 1px solid #bababa !important;
    background-color: #fff !important;
    height: 4.1876em !important;
    border-radius: 5px !important;
}
.dark-button {
    color: rgb(255, 255, 255) !important;
    border-width: 1px !important;
    width: 100% !important;
    border: 1px solid #000 !important;
    background-color: #000 !important;
    height: 4.1876em !important;
    border-radius: 5px !important;
}


.react-multiple-carousel__arrow__custom {
    position: absolute;
    right: calc(4% + 1px);
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
    max-width: 43px;
}

.inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1200px;
    flex-direction: row;
}
.carousel-container {
    width: 100%;
    padding: 40;
    margin: 'auto 0';
    
}

.ul {
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    align-self: 'center';
    justify-content: space-between;
    width: 1200px;
    
    margin: 0;
    padding: 0;
}
.column > b:hover{
    text-decoration: underline !important;
}
.column {
    display: inline-block;
}
.roboto {
    font-family: 'Roboto', sans-serif !important;
}
.uppercase {
    font-family: 'FiraGo';
    -moz-font-feature-settings: 'case';
    -webkit-font-feature-settings: 'case';
    font-feature-settings: 'case' on;
}
.li-menu-item:hover {
    text-decoration: underline !important;
}
.li-menu-item {
    
    flex: 0 1 auto;
    -moz-font-feature-settings: 'case';
    -webkit-font-feature-settings: 'case';
    font-feature-settings: 'case' on;
    list-style-type: none;
    padding: 10px;
    text-align: center;
    min-width: 40px;
    cursor: pointer;
    font-size: 10pt;
    background: #888;
}
.li-menu-item-active:hover {
    text-decoration: underline !important;
}
.li-menu-item-active {
    -moz-font-feature-settings: 'case';
    -webkit-font-feature-settings: 'case';
    font-feature-settings: 'case' on;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
    padding: 10px;
    text-align: center;
    min-width: 100px;
    cursor: pointer;
    font-size: 10pt;
    
}   

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), #ffffff66) !important;
    color: -internal-light-dark(black, white) !important;
}

.brand-item {
    border: 1px solid #999;
    border-radius: 4px;
    height: 60px;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
}
.letter {
    cursor: pointer;
    padding: 7px;
    font-weight: bold;
    color: #888;
    &:hover {
        font-weight: bold;
      }
}

.lettersection {
    height: 120px;
    padding: 20px;
    border-top-color: #888;
    border-top: 1px solid;
}

.colul {
    display: -ms-flexbox;           /* IE 10 */
    display: -webkit-flex;          /* Safari 6.1+. iOS 7.1+ */
    display: flex;
    -webkit-flex-flow: wrap column; /* Safari 6.1+ */
    flex-flow: wrap column;
    max-height: 120px;              /* Limit height to whatever you need */
  }   

  .li {
    list-style-type: none;
  }
  .brandsLetters {
      height: 600px;
      overflow-y: scroll;
  }

  .error {
    font-size: 10pt !important;
    color: darkred !important;
  }

  .MuiPaper-root-MuiDialog-paper { 
    max-height: '100%' !important;
  }
  .MuiDialog-paperScrollPaper {
    margin: 0 !important;
    max-height: calc(100%) !important;
    
  }
  .iiz__hint {
    right: 43% !important;
    bottom: 20px !important;
  }
  .disable-text-selection{
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
 }

 .MuiDialog-paperScrollPaper {
    overflow-x : hidden !important;
  }

  .gChild:hover {
    text-decoration: underline !important;
  }

  .beige-header {
    background-color: 'red' !important;
    max-width: 1200px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35px;
    flex-direction: row !important;
  }
  .underline-on-hover {
    &:hover {
      text-decoration: underline;
    }
  }  