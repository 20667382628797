.carousel {
  width: 100%;
  height: 300px;
  position: relative;
}

.carousel-slide {
  position: relative;
  text-align: center;
  height: 100%;
  margin: 0 15px;
}

.carousel-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-current {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.swiper-button-next {
  color: rgb(0, 0, 0);
}
.swiper-button-prev {
  color: rgb(0, 0, 0);
}
.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.--swiper-navigation-color {
  color: #fff !important;
}
.--swiper-pagination-color {
  color: #fff !important;
}


.mySwiper3 .swiper-slide {
  opacity: 0.6;
}

.mySwiper3 .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper6 {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
